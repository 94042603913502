@import '../base/variables';
@import "../mixins/breakpoint";
@import "../base/utilities";

.cont {
    :global(.apexcharts-legend) {
        justify-content: center !important;
        margin-top: 16px !important;
        overflow: hidden;
    }

    :global(.apexcharts-legend-series) {
        margin-right: 12px !important;
    }


    :global(.apexcharts-legend-marker) {
        width: 16px !important;
        height: 16px !important;
    }

    :global(.apexcharts-legend-text) {
        font-size: toRem(15px) !important;
        padding-left: 20px;

        @include media-breakpoint-down("md") {
            font-size: toRem(12px) !important;
            line-height: toRem(18px) !important;
        }
    }

    :global(.apexcharts-text tspan) {
        @include media-breakpoint-down("md") {
            font-size: toRem(12px) !important;
            line-height: toRem(18px) !important;
        }
    }

    :global(.apexcharts-datalabel) {
        font-size: toRem(14px) !important;
        line-height: toRem(21px) !important;        
    }

    &-title {
        text-align: center;
        font-size: toRem(20px);
        line-height: toRem(30px);
        font-weight: 500;

        @include media-breakpoint-down("md") {
            font-size: toRem(12px);
            line-height: toRem(18px);
            text-align: start;
            margin-top: 24px;
        }
    }
}

.area-line {
    :global(.apexcharts-xaxis-label) {
        font-weight: 400 !important;
    }

    :global(.apexcharts-yaxis-label) {
        font-weight: 400 !important;
    }
}