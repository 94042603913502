@import "../../../assets//styles/base/variables.scss";
@import "../../../assets//styles/base/utilities";
@import "../../../assets//styles/mixins/breakpoint";

.cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-direction: column;

    @include media-breakpoint-down("md") {
        align-items: flex-start;
        margin-top: 32px;
    }

    &-title {
        font-size: toRem(20px);
        line-height: toRem(30px);
        font-weight: 600;
        @include media-breakpoint-down("md") {
            font-size: 14px;
        }
    }

    &-bar {
        display: flex;
        border-radius: 10px;
        height: 47px;
        width: 100%;
        color: $color-system-white;

        &-item {
            display: flex;
            align-items: center;
            padding-left: 16px;
            font-size: toRem(14px);
            line-height: toRem(21px);
            @include media-breakpoint-down("xs") {
                padding-left: 4px;
                font-size: 12px;
            }
        }

        &-left {

            background: #FD7278;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &-right {
            background: #00A7B7;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    &-labels {
        margin-top: 8px;
        display: flex;
        gap: 16px;
        font-weight: 400;

        @include media-breakpoint-down("md") {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 2px;
        }

        span {
            font-size: toRem(14px);
            line-height: pxToRem(21px);
            color: $color-system-blue;
        }

        svg {
            font-size: toRem(20px);
        }

        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
        }

        &-left {
            svg {
                color: #FD7278;
            }
        }

        &-right {
            svg {
                color: #00A7B7;
            }
        }
    }
}