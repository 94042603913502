@import "../../../assets/styles/base/variables.scss";
@import "../../../assets/styles/mixins/breakpoint";
@import "../../../assets/styles/base/utilities";

.main {}

.header {
    display: flex;
    align-items: center;
    gap: 24px;

    @include media-breakpoint-down("md") {
        flex-direction: column;
        align-items: baseline;
    }

    &-title {
        font-size: toRem(24px);
        line-height: toRem(36px);
        font-weight: bold;
        letter-spacing: 0px;
        color: $color-system-blue;
    }
}

.section-icon {
    width: 44px;
    height: 44px;

    svg {
        font-size: toRem(24px);
    }

    @include media-breakpoint-down("md") {
        width: 40px;
        height: 40px;

        svg {
            font-size: toRem(23px);
        }

    }
}

.summary {
    margin-top: 40px;

    p {
        text-align: left;
        font-size: toRem(20px);
        line-height: toRem(30px);
        letter-spacing: 0px;
        color: $color-system-heading;
    }

    strong {
        font-size: toRem(20px);
        line-height: toRem(30px);
        font-weight: 600;
    }

    @include media-breakpoint-down("md") {
        margin-top: 20px;

        p {
            font-size: toRem(14px);
            line-height: toRem(21px);
        }

    }
}

.explore-wrap {
    margin-top: 16px;

    &-link {
        font-size: toRem(16px);
        line-height: toRem(25px);
        font-weight: 500;
        letter-spacing: 0px;
        color: $color-system-heading;
        opacity: 1;

        svg {
            margin-left: 8px;
            font-size: toRem(16px);
            color: $color-system-heading;
        }

        &:hover {
            text-decoration: underline;
        }

        @include media-breakpoint-down("md") {
            font-size: toRem(12px);
            line-height: toRem(18px);

        }
    }
}