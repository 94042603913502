@import '../base/variables';
@import "../mixins/breakpoint";

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-system-white;
    border-radius: 10px;
    width: 72px;
    height: 72px;

    svg {
        font-size: 40px;
    }

    @include media-breakpoint-down("md") {
        width: 40px;
        height: 40px;

        svg {
            font-size: 23px;
        }

    }
}