@import '../base/variables';
@import "../mixins/breakpoint";
@import "../base/utilities";

.sdg {
    :global(.container) {
        @include media-breakpoint-down("lg") {
            max-width: 100%;
        }
    }

    &-small {
        .sdg-row {
            &-item {
                margin-top: 10px;
                padding: 12px 0px;
            }
        }
    }

    &-cont {
        margin-left: 15px;
        margin-right: 15px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down("md") {
            align-items: flex-start !important;
        }

        &-item {
            font-size: toRem(14px);
            line-height: toRem(21px);
            letter-spacing: 0px;
            color: $color-system-heading;

            &-large {
                font-size: 20px;
                line-height: 30px;
                font-weight: 500;
            }

            &-sub-heading {
                font-size: toRem(12px);
                line-height: toRem(18px);
                letter-spacing: 0px;
                color: #707070;
                padding-left: 4px;

                @include media-breakpoint-down("sm") {
                    font-size: toRem(10px);
                    line-height: toRem(16px);
                }
            }

            @include media-breakpoint-down("sm") {
                font-size: toRem(12px);
                line-height: toRem(18px);
            }

            @include media-breakpoint-down("md") {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &-row {
        &-item {
            margin-top: 15px;
            border: 1px solid $color-system-heading;
            border-radius: 8px;
            opacity: 1;
            padding: 16px 0px;
            font-size: toRem(18px);
            line-height: toRem(27px);
            font-weight: 500;
            letter-spacing: 0px;
            color: $color-system-heading;

            span {
                font-size: toRem(18px);
                line-height: toRem(27px);
            }
            @include media-breakpoint-down("md") {
                margin-top: 6px !important;
            }

            @include media-breakpoint-down("sm") {
                font-size: toRem(12px);
                line-height: toRem(18px);

                span {
                    font-size: toRem(12px);
                    line-height: toRem(18px);
                }
            }

            &-elem {
                svg {
                    font-size: toRem(15px);
                    margin-left: 8px;
                }

                :global(.fa-up-long) {
                    color: $color-system-green;
                }

                :global(.fa-down-long) {
                    color: $color-system-pink;
                }

                &-background {
                    :global(.fa-up-long) {
                        color: $color-system-white;
                    }

                    :global(.fa-down-long) {
                        color: $color-system-white;
                    }
                }
            }
        }
    }
}