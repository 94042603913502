@import "../../../assets/styles/base/variables.scss";
@import "../../../assets/styles/mixins/breakpoint";
@import "../../../assets/styles/base/utilities";

.cont {
    background-color: #1A2744;
    padding-top: 80px;
    padding-bottom: 80px;
    padding: 80px 64px;
    color: $color-system-white;

    @include media-breakpoint-down("md") {
        flex-direction: column;
        padding: 40px 32px;
    }

    a {
        font-size: toRem(18px);
        line-height: toRem(27px);
        font-weight: 400 !important;
        color: $color-system-white;
        display: block;
        margin-bottom: 8px;

        &:hover {
            text-decoration: underline;
            text-decoration-color: $color-system-white;
        }

        @include media-breakpoint-down("md") {
            font-size: toRem(14px);
            line-height: toRem(21px);
        }
    }

    :global(.row) {
        @include media-breakpoint-down("md") {
            text-align: center;
        }
    }
}

.subscribe {
    &-title {
        font-size: toRem(40px);
        line-height: toRem(60px);
        font-weight: bold;

        @include media-breakpoint-down("md") {
            font-size: toRem(24px);
            line-height: toRem(35px);
            font-weight: 600;
        }

    }

    &-summary {
        margin-top: 32px;
        font-size: toRem(18px);
        line-height: toRem(27px);
        font-weight: 400;
        max-width: 560px;

        @include media-breakpoint-down("xl") {
            max-width: none;
        }

        @include media-breakpoint-down("md") {
            margin-top: 16px;
            font-size: toRem(14px);
            line-height: toRem(21px);
        }
    }

    &-input-cont {
        margin-top: 32px;

        input {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            color: #555555;
            padding: 10px 20px;
            border: none;
            outline: none;
            min-width: 400px;

            @include media-breakpoint-down("xl") {
                min-width: auto;
            }

            @include media-breakpoint-down("sm") {
                padding: 8px 16px;
                width: 60%;
                font-size: toRem(14px);
            }
        }

        button {
            border: none;
            min-width: 140px;
            font-size: toRem(16px);
            font-weight: 500;
            background: #EE7279;
            color: $color-system-white;
            padding: 10px 20px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            cursor: pointer;
            opacity: 1;
            transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transform: translate3d(0, 0, 0);

            &:hover {
                opacity: 0.9;
            }

            &:disabled {
                opacity: 0.38;
            }

            @include media-breakpoint-down("xl") {
                min-width: auto;
            }

            @include media-breakpoint-down("sm") {
                padding: 8px 16px;
                font-size: toRem(14px);
            }

            span {
                margin-right: 6px;
            }

            img {
                margin: -2px 0px;
            }
        }

        &-error {
            color: $color-system-validation-error;
            margin: 2px 4px;
            font-size: toRem(14px);
            line-height: toRem(21px);
        }

        &-toast-success {
            display: flex;
            align-items: center;
            min-width: 300px;

            :global(.fa-envelope) {
                font-size: toRem(26px);
                margin-right: 10px;
            }

            @include media-breakpoint-down("sm") {
                width: 100%;
            }
        }

        @include media-breakpoint-down("md") {
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
}

.links {
    @include media-breakpoint-down("md") {
        margin-top: 32px;
    }
}

.powered-by {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: $color-system-white;

    img {
        object-fit: contain;

        @include media-breakpoint-down("md") {
            height: 50px;
        }
    }

    &-title {
        font-size: toRem(18px);
        line-height: toRem(27px);
        font-weight: 400;

        @include media-breakpoint-down("md") {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &-gender {
        font-size: toRem(16px);
        line-height: toRem(25px);
        font-weight: 400;

        @include media-breakpoint-down("md") {
            font-size: 12px;
            line-height: 18px;
        }
    }

    @include media-breakpoint-down("md") {
        margin-top: 32px;
        gap: 8px;
    }
}