@import "../../../assets/styles/base/variables.scss";
@import "../../../assets/styles/mixins/breakpoint";
@import "../../../assets/styles/base/utilities";

.section {
    border-radius: 15px;
    min-height: 418px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;

    @include media-breakpoint-down("sm") {
        min-height: 480px;
        margin: 0 60px;
    }

    &-date {
        color: $color-system-white;
        font-size: toRem(14px);
        line-height: toRem(21px);
        font-weight: 600;
    }

    &-top {
        min-height: 220px;

        @include media-breakpoint-down("md") {
            min-height: 120px;
        }
    }

    &-title {
        color: $color-system-white;
        font-size: toRem(26px);
        line-height: toRem(36px);
        font-weight: 600;
        margin-top: 4px;
        text-align: left;
    }

    &-read-more {
        color: $color-system-red-primary;
        font-size: toRem(14px);
        line-height: toRem(21px);
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}