@import "../base/variables.scss";
@import "../mixins/breakpoint";
@import "../base/utilities";

.common-section {
  padding-top: 160px;
  padding-bottom: 160px;
  background: #fcfcfc;

  @include media-breakpoint-down("md") {
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.common-border-top {
  border-top: 1px solid $color-system-light-grey;
}

.site-info {
  @extend .common-border-top;
  @extend .common-section;

  &-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-cont {
    max-width: 424px;

    @include media-breakpoint-down("xl") {
      max-width: 360px;
    }

    @include media-breakpoint-down("lg") {
      max-width: 50%;
      margin-bottom: 32px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include media-breakpoint-down("md") {
      max-width: 100%;
      padding: 0px;
    }
  }

  &-icon-cont {
    display: flex;
    justify-content: center;

    @include media-breakpoint-down("md") {
      justify-content: flex-start;
    }
  }

  &-title {
    text-align: left;
    font-size: toRem(22px);
    line-height: toRem(32px);
    font-weight: 600;
    color: $color-system-blue;

    @include media-breakpoint-down("md") {
      font-size: toRem(18px);
      line-height: toRem(24px);
    }

    @include media-breakpoint-down("sm") {
      font-size: toRem(16px);
      line-height: toRem(21px);
    }
  }

  &-content-cont {
    min-height: 400px;
    // border-right: 2px solid $color-system-light-grey;
    padding-right: 32px;
    padding-left: 32px;
    margin-top: 34px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: $color-system-light-grey;
      width: 2px;
      right: -48px;
      min-height: 370px;

      @include media-breakpoint-down("xl") {
        right: 0;
      }

      @include media-breakpoint-down("lg") {
        right: 0;
        min-height: auto;
      }

      @include media-breakpoint-down("md") {
        content: none;
      }

      @include media-breakpoint-between("md", "lg") {
        right: -40px;
        min-height: auto;
      }
    }

    @include media-breakpoint-down("xl") {
      min-height: 420px;
    }

    @include media-breakpoint-down("lg") {
      min-height: auto;
      padding-right: 16px;
      margin-bottom: 34px;
    }

    @include media-breakpoint-down("md") {
      min-height: auto;
      margin-top: 18px;
      margin-bottom: 18px;
      padding-left: 0;
    }

    @include media-breakpoint-down("sm") {
      font-size: toRem(14px);
      line-height: toRem(21px);
    }
  }

  &-no-border {
    &::before {
      content: none;
    }
  }

  &-content {
    margin-top: 30px;

    @include media-breakpoint-down("md") {
      margin-top: 15px;
    }

    p {
      font-size: toRem(16px);
      line-height: toRem(25px);
      letter-spacing: 0px;
      color: $color-system-heading;
      margin-bottom: 12px;
      text-align: left;
    }
  }

  &-btn-cont {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include media-breakpoint-down("lg") {
      margin-top: 22px;
    }

    @include media-breakpoint-down("md") {
      justify-content: flex-start;
    }

    @include media-breakpoint-down("sm") {
      button {
        font-size: toRem(12px);
        line-height: toRem(18px);
        margin-bottom: 24px;
      }
    }
  }
}

.national-price {
  @extend .common-section;

  &-main {
    &-row {
      align-items: center;
      justify-content: center;
    }

    :global(#svg-main) {
      margin-top: -50px;
      width: 564px !important;
      height: 700px !important;
      cursor: auto;
      touch-action: auto;

      @include media-breakpoint-down("xl") {
        margin-top: -40px;
        width: 100% !important;
      }

      @include media-breakpoint-down("lg") {
        margin-top: auto;
        height: 640px !important;
      }
    }

    &-active-uk {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      letter-spacing: 0px;
      color: $color-system-heading;
      opacity: 1;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &-explore-link {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      letter-spacing: 0px;
      color: $color-system-heading;
      opacity: 1;

      svg {
        margin-left: 8px;
        font-size: 16px;
        color: $color-system-heading;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &-sdg {
      margin-top: 38px;

      &-label-cont {
        display: flex;
        gap: 36px;
      }
    }
  }

  :global(.map-svg) {
    g[data-code] {
      cursor: auto !important;
    }
  }

  :global(.tablekeyFooter) {
    padding-right: 50px;
    justify-content: flex-end;

    @include media-breakpoint-down("lg") {
      justify-content: center;
      padding-right: 0;
    }
  }

  :global(.heatSpotCircle) {
    width: 14px !important;
    height: 14px !important;
  }

  :global(.tablekeyFooter) {
    gap: 16px !important;
  }
}

.hero-section {
  background: transparent linear-gradient(to right, #ffffff 0%, #ffffff 50%, #00a7b7 100%) 0% 0% no-repeat padding-box;

  // @include media-breakpoint-down("md") {
  //   @media screen and (max-width: 1100px) {
  //     background: transparent
  //       linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #00a7b7 100%) 0% 0%
  //       no-repeat padding-box;
  //   }
  @include media-breakpoint-down("md") {
    background: none;
  }

  // :global(.app-header) {
  //     // background: transparent;
  //     @include media-breakpoint-down("md") {
  //         background: $color-system-white;
  //     }
  // }

  :global(.actionBtnsWrap) {
    :global(.fa-database) {
      color: $color-system-red-primary;
    }

    :global(.fa-book-open-reader) {
      color: $color-system-green;
    }

    button {
      &:hover {
        svg {
          color: $color-system-white;
        }
      }
    }
  }

  &-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // padding: 80px 80px;
    padding: 80px 80px 80px 80px;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-down("md") {
      flex-direction: column;
      padding: 30px 15px 30px 15px;
    }

    @include media-breakpoint-between(md, xl) {
      padding: 60px 60px 60px 60px;
    }
  }

  // &-cont{
  //     @media screen and (max-width: 1100px) {
  //         padding-top: 30px;
  //         padding-bottom: 0px;
  //         padding-left: 15px;
  //         padding-right: 15px;
  //     }
  // }

  &-left {
    width: 60%;

    @include media-breakpoint-down("md") {
      width: 100%;
    }

    h1 {
      font-size: toRem(80px);
      line-height: toRem(120px);
      font-weight: 600;

      @include media-breakpoint-between(md, xl) {
        font-size: toRem(64px);
        line-height: toRem(90px);
      }

      @include media-breakpoint-down(md) {
        font-size: toRem(24px);
        line-height: toRem(35px);
      }
    }

    p {
      font-size: toRem(24px);
      line-height: toRem(35px);
      font-weight: 400;

      @include media-breakpoint-down(md) {
        font-size: toRem(14px);
        line-height: toRem(21px);
      }
    }
  }

  &-divider-red {
    width: 94%;
    height: 9px;
    background-color: $color-system-red-primary;
    border-radius: 10px;
    margin: 40px 0;

    @include media-breakpoint-between(md, xl) {
      margin: 20px 0;
    }

    @include media-breakpoint-down("md") {
      margin: 20px 0;
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include media-breakpoint-between(md, xl) {
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    @include media-breakpoint-down("md") {
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 0px;
      display: none;
    }

    @include media-breakpoint-down("xs") {
      padding: 0;
    }

    img {
      object-fit: contain;

      @include media-breakpoint-between(md, xl) {
        // margin-top: 32px;
        // margin-bottom: 16px;
        width: 400px;
      }

      @include media-breakpoint-down("xs") {
        width: 400px;
        height: 400px;
        padding-top: 30px;
      }
    }
  }

  &-button {
    button {
      color: $color-system-white;

      &:hover {
        color: $color-system-white;

        svg {
          color: $color-system-white;
        }
      }

      @include media-breakpoint-down("md") {
        display: none;
      }
    }

    h1 {
      @include media-breakpoint-down("md") {
        font-size: toRem(30px);
        // margin-top: 16px;
        line-height: toRem(35px);
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
}

.uk-story {
  background: $color-system-green;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding-top: 80px;
  padding-bottom: 80px;

  @include media-breakpoint-down("md") {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-system-blue;
    font-size: 70px;
    line-height: 106px;

    @include media-breakpoint-down("md") {
      font-size: 24px;
      line-height: 35px;
    }

    @include media-breakpoint-between(md, xl) {
      font-size: 50px;
      line-height: 76px;
    }
  }

  &-row {
    margin-top: 80px;

    @include media-breakpoint-down("md") {
      margin-top: 40px;
    }

    @include media-breakpoint-between(md, xl) {
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
    }
  }

  &-text-overlay {
    max-width: 1000px;
  }

  &-left {
    position: absolute;
    bottom: 0;
    left: -200px;
    display: flex;

    @include media-breakpoint-down("md") {
      display: none;
    }
  }

  &-right {
    height: 600px;
    display: flex;
    flex: 1;
    margin-top: 80px;
    position: absolute;
    top: 0;
    right: 0;

    @include media-breakpoint-down("md") {
      display: none;
    }
  }

  &-left-stat {
    display: flex;
    z-index: 1;

    @include media-breakpoint-down("md") {
      justify-content: center;
    }

    @include media-breakpoint-between(md, lg) {
      padding-bottom: 24px;
    }

    @include media-breakpoint-between(md, xl) {
      justify-content: center;
    }
  }

  &-left-stat-box {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-between(md, xl) {
      align-items: center;
    }
  }

  &-left-stat-figure {
    font-size: 100px;
    line-height: 130px;
    color: $color-system-white;

    @include media-breakpoint-down("md") {
      font-size: 40px;
      line-height: 60px;
      display: flex;
      justify-content: center;
    }

    @include media-breakpoint-between(md, xl) {
      font-size: 80px;
      line-height: 120px;
    }
  }

  svg {
    width: 26px;
    height: 42px;
    padding-right: 8px;

    @include media-breakpoint-down("md") {
      width: 12px;
      height: 22px;
    }

    @include media-breakpoint-between(md, xl) {
      width: 22px;
      height: 40px;
    }
  }

  &-left-stat-text {
    font-size: 24px;
    line-height: 35px;
    color: $color-system-blue;

    @include media-breakpoint-down("md") {
      font-size: 16px;
      line-height: 25px;
      padding: 12px 12px;
      text-align: center;
    }

    @include media-breakpoint-between(md, xl) {
      font-size: 24px;
      line-height: 35px;
      padding: 12px 0;
      text-align: center;
    }
  }

  &-right-stat {
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    align-items: center;

    @include media-breakpoint-down("md") {
      justify-content: center;
    }

    @include media-breakpoint-between(md, xl) {
      justify-content: center;
    }
  }

  &-right-stat-box {
    background: #fd7278;
    border: 5px solid $color-system-white;
    border-radius: 50px;
    display: flex;
    text-align: center;
    gap: 16px;
    flex-direction: column;
    padding: 32px;
    max-width: 234px;
    max-height: 180px;
    z-index: 1;

    @include media-breakpoint-down("md") {
      border: 2px solid $color-system-white;
      border-radius: 10px;
      max-width: 150px;
      // min-width: 150px;
      gap: 0;
      padding: 16px 16px;
    }

    @include media-breakpoint-between(md, xl) {
      border: 2px solid $color-system-white;
      border-radius: 25px;
      max-width: 392px;
      // min-width: 392px;
      gap: 0;
      padding: 26px 36px;
    }
  }

  &-right-stat-figure {
    font-size: 50px;
    line-height: 30px;
    color: $color-system-white;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down("md") {
      font-size: 30px;
      line-height: 46px;
    }

    @include media-breakpoint-between(md, xl) {
      font-size: 50px;
      line-height: 30px;
    }
  }

  &-right-stat-text {
    font-size: 18px;
    line-height: 24px;
    color: $color-system-blue;

    @include media-breakpoint-down("md") {
      font-size: 12px;
      line-height: 14px;
    }

    @include media-breakpoint-between(md, xl) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 8px;
    }
  }

  h1 {
    font-weight: 600;
  }

  img {
    object-fit: contain;
  }
}

.female-incorporation {
  @extend .common-section;
  @extend .common-border-top;
  background-color: #f9f9f9;

  &-chart {
    margin-top: 40px;
  }
}

.generation-shift {
  @extend .common-section;
  @extend .common-border-top;

  &-chart {
    @include media-breakpoint-between(md, lg) {
      margin-top: 32px;
    }
  }
}

.ethnicity-uk {
  @extend .common-section;
  @extend .common-border-top;
  background-color: #fcfcfc;

  &-stat {
    max-width: 484px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    @include media-breakpoint-down("md") {
      margin: 0 !important;
      max-width: none !important;
    }
  }

  &-text {
    color: $color-system-blue;
    font-size: 70px;
    line-height: 35px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-bottom: 10px solid #fd7278;
    padding-bottom: toRem(32px);

    @include media-breakpoint-down("md") {
      font-size: 40px;
      line-height: 60px;
      padding-bottom: 0;
    }
  }

  &-arrow {
    padding-right: 16px;
    width: 26px;
    height: 42px;

    @include media-breakpoint-down("md") {
      width: 18px;
      height: 30px;
    }
  }

  &-summary {
    font-size: 24px;
    line-height: 35px;
    color: #012348;
    text-align: center;
    margin-top: toRem(32px);
    font-weight: 500;

    @include media-breakpoint-down("md") {
      font-size: 14px;
      line-height: 21px;
      margin-top: toRem(10px);
      font-weight: 800;
      padding: 0 30px;
    }
  }
}

.generation-international-trade {
  @extend .common-section;
  @extend .common-border-top;

  :global(.row) {
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down("md") {
      flex-direction: column-reverse;
    }
  }

  &-chart-break {
    margin-top: 50px;

    @include media-breakpoint-down("md") {
      margin-top: 0px;
    }
  }
}

.female-led-sector {
  @extend .common-section;
  @extend .common-border-top;
  background: #f7f7f7;

  &-main {
    :global(.row) {
      justify-content: center;

      :global(.row) {
        :global(:first-child) {
          display: flex;
        }

        :global(:last-child) {
          display: flex;
          justify-content: end;
          flex-direction: row;
        }
      }
    }

    :global(#svg-main) {
      margin-top: -80px;
      width: 564px !important;
      height: 854px !important;
      cursor: auto;

      @include media-breakpoint-down("xl") {
        margin-top: -80px;
        width: 100% !important;
      }

      @include media-breakpoint-down("lg") {
        margin-top: auto;
        height: 740px !important;
      }
    }

    &-active-uk {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      letter-spacing: 0px;
      color: $color-system-heading;
      opacity: 1;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &-explore-link {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      letter-spacing: 0px;
      color: $color-system-heading;
      opacity: 1;

      svg {
        margin-left: 8px;
        font-size: 16px;
        color: $color-system-heading;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &-sdg {
      margin-top: 38px;

      @include media-breakpoint-down("md") {
        flex-direction: column;
        gap: 40px;
      }
    }
  }
}

.secured-debt {
  @extend .common-section;
  @extend .common-border-top;
  background: #f7f7f7;

  &-circle-title {
    font-size: toRem(20px);
    line-height: toRem(30px);
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding-bottom: 18px;

    @include media-breakpoint-down("md") {
      font-size: toRem(12px);
      line-height: toRem(18px);
      margin-top: 40px;
    }
  }

  &-right {
    @include media-breakpoint-down("lg") {
      margin-top: 32px;
    }
  }
}

.investment-led-company {
  @extend .common-section;
  @extend .common-border-top;
  background: #f7f7f7;

  &-chart-cont {
    :global(.row) {
      align-items: center;
    }

    margin-top: 40px;

    &-tcr {
      display: flex;
      flex-direction: column;
      gap: 28px;
      align-items: center;
      max-width: 260px;

      @include media-breakpoint-down("lg") {
        max-width: none;
        margin-bottom: 24px;
      }

      @include media-breakpoint-down("md") {
        gap: 12px;
        margin-bottom: 40px;
      }

      svg {
        font-size: 48px;
        color: #012348;

        @include media-breakpoint-down("md") {
          font-size: 48px;
        }
      }

      &-text {
        font-size: toRem(24px);
        line-height: toRem(35px);
        font-weight: 500;

        @include media-breakpoint-down("md") {
          font-size: toRem(16px);
          line-height: toRem(25px);
        }
      }

      &-amount {
        font-size: toRem(80px);
        line-height: toRem(35px);
        font-weight: bold;
        color: $color-system-red-primary;

        @include media-breakpoint-down("md") {
          font-size: toRem(40px);
        }
      }
    }

    &-info {
      margin-top: 16px;
      margin-left: 12px;
      display: flex;
      gap: 40px;

      :global(span) {
        font-size: toRem(14px);
        line-height: toRem(21px);
        color: #282d32;
      }
    }
  }
}

.latest-news {
  @extend .common-section;
  background: $color-system-red-primary;

  :global(.row) {
    align-items: center;
  }

  :global(.news-item) {
    @include media-breakpoint-down("md") {
      margin-top: 24px;
    }
  }

  &-left {
    &-title {
      font-size: toRem(40px);
      line-height: toRem(60px);
      font-weight: bold;
      color: $color-system-white;

      @include media-breakpoint-down("md") {
        font-size: toRem(24px);
        line-height: toRem(35px);
      }
    }

    &-summary {
      font-size: toRem(20px);
      line-height: toRem(30px);
      color: $color-system-white;
      margin-top: 24px;

      @include media-breakpoint-down("md") {
        font-size: toRem(14px);
        line-height: toRem(21px);
      }
    }

    button {
      margin-top: 28px;
      border-color: $color-system-white;
      background-color: transparent;
      color: $color-system-white;
    }
  }
}

.partners {
  @extend .common-section;
  // @extend .common-border-top;
  padding-top: 80px;
  padding-bottom: 80px;
  background: $color-system-white;

  &-title {
    font-size: toRem(40px);
    line-height: toRem(60px);
    color: $color-system-blue;
    font-weight: bold;
    text-align: center;

    @include media-breakpoint-down("md") {
      font-size: toRem(24px);
      line-height: toRem(35px);
    }
  }

  .partners-logo {
    margin-top: 32px;
    align-items: center;
    justify-content: center;
    padding: 0px;

    li {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 0;

      img {
        object-fit: contain;
      }

      @include media-breakpoint-down("lg") {
        margin-bottom: 24px;
      }
    }
  }
}

.upcoming-events {
  @extend .common-section;
  background-image: url("/images/events_bg.png");
  background-repeat: no-repeat;
  // height: 1280px;
  // object-fit: contain;
  background-size: cover;
  font-weight: 600;

  &-container {
    background: #e6e6e6;
    border-radius: 10px;
    padding: toRem(40px);
    margin-top: 100px;
    margin-left: 154px;
    margin-bottom: 206px;
    max-width: 693px;
    opacity: 0.9;

    @include media-breakpoint-down("md") {
      margin: 30px;
      padding: toRem(24px);
    }
  }

  &-heading {
    color: #0ca8b8;
    font-size: toRem(30px);
    line-height: toRem(46px);

    @include media-breakpoint-down("md") {
      font-size: toRem(18px);
      line-height: toRem(36px);
    }
  }

  &-sub-heading {
    color: $color-system-blue;
    font-size: toRem(40px);
    line-height: toRem(60px);

    @include media-breakpoint-down("md") {
      font-size: toRem(20px);
      line-height: toRem(27px);
    }
  }

  &-date-time {
    color: $color-system-heading;
    font-size: toRem(18px);
    line-height: toRem(27px);
    font-weight: 500;
    padding-top: 16px;

    @include media-breakpoint-down("md") {
      font-size: toRem(12px);
      line-height: toRem(18px);
    }
  }

  &-summary {
    p {
      color: $color-system-heading;
      font-size: toRem(18px);
      line-height: toRem(27px);
      font-weight: 400;
      padding-top: 16px;
    }

    @include media-breakpoint-down("md") {
      p {
        font-size: toRem(12px);
        line-height: toRem(18px);
      }
    }
  }

  &-button {
    margin-top: 40px;

    @include media-breakpoint-down("md") {
      font-size: toRem(12px);
      line-height: toRem(18px);
    }
  }
}

.delve-data {
  background: $color-system-green;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding-top: 80px;
  padding-bottom: 80px;
  color: $color-system-white;
  overflow: hidden;

  p {
    color: $color-system-white;
  }

  @include media-breakpoint-down("md") {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-title {
    display: flex;
    align-items: center;
    color: $color-system-white;
    font-size: 40px;
    line-height: 60px;
    font-weight: bold;

    @include media-breakpoint-down("md") {
      font-size: 24px;
      line-height: 35px;
    }
  }

  &-summary {
    font-size: toRem(20px);
    line-height: toRem(30px);
    font-weight: normal;
    margin-top: toRem(38px);

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 21px;
      margin-top: toRem(30px);
    }
  }

  &-container {
    display: flex;
    align-items: center;

    @include media-breakpoint-down("md") {
      display: block;
      max-width: 100%;
    }
  }

  &-left {
    position: absolute;
    top: 0;
    left: 0px;

    img {
      @include media-breakpoint-down("lg") {
        max-width: 150px;
      }
    }

    @include media-breakpoint-down("md") {
      display: none;
    }
  }

  &-right {
    display: flex;
    flex: 1;
    position: absolute;
    bottom: 0;
    right: 0;

    @include media-breakpoint-down("md") {
      display: none;
    }
  }

  &-stat-image {
    max-width: 70vw;

    @include media-breakpoint-down("md") {
      max-width: 100vw;
      height: 300px;
      margin-top: 40px;
    }
  }

  &-button {
    max-width: 264px;
    background: transparent;
    color: $color-system-white;
    border-color: $color-system-white;
    font-weight: 600;
  }

  img {
    object-fit: contain;
  }
}